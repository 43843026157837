import { graphql } from 'gatsby'
import * as React from "react"
import FindDreamJob from "../../components/careers/finddreamjob"
import JobOpenings from "../../components/careers/jobopenings"
import JobSearchForm from "../../components/careers/jobsearchform"
import Layout from "../../components/layout/layout"
import Seo from "../../components/seo"

export const query = graphql`
  query($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["common"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    allContentfulLocations(filter: { node_locale: { eq: "en-US" } }) {
      nodes {
        slug
        name
      }
    }

    allContentfulSkill(filter: { node_locale: { eq: "en-US" } }) {
      nodes {
        slug
        name
      }
    }

    allContentfulJobs(
      filter: {
        locations: { elemMatch: { slug: { ne: null }, node_locale: { eq: "en-US" } } }
      }
      sort: { fields: updatedAt, order: DESC }
    ) {
      nodes {
        id
        jobSlug
        title
        updatedAt(formatString: "MM/DD/YY")
        remote
        office
        urgent
        description {
          description
        }
        benefits {
          benefit
        }
        benefitsDescription {
          benefitsDescription
        }
        requirements {
          requirements
        }
        ourclient {
          ourclient
        }
        locations {
          id
          slug
          name
          shortName
        }
        skills {
          name
          slug
        }
      }
    }
  }
`


const JobOpportunitiesPage = ({ data, location }) => {
  const pageTitle = "Job Opportunities"
  const pageDescription = "Look at our Job Opportunities"
  const lang = 'EN'
  const meta = []

  const breadcrumbMap = [
    { title: "Careers", url: "/careers/" },
    { title: "Job Opportunities", url: "#" }
  ]

  /** Getting search params from url */
  const params = new URLSearchParams(location.search);
  const keywordParam = params.get("keyword") ? params.get("keyword") : '';
  let locationParam = params.get("location") ? params.get("location") : 'all';
  const skillParam = params.get("skill") ? params.get("skill") : 'all';
  // const remoteParam = params.get("remote") === null ? true : params.get("remote") === 'true';
  // const officeParam = params.get("office") === null ? true : params.get("office") === 'true';

  var {
    nodes: jobs
  } = data.allContentfulJobs;
  var {
    nodes: locations
  } = data.allContentfulLocations;
  var {
    nodes: skills
  } = data.allContentfulSkill;

  const keywords = keywordParam.split(" ");

  // Check if  keyword have a Location - legacy
  // if( keywordParam && keywordParam !== '' && locationParam === 'all' && skillParam === 'all' ) {
  //   keywords.map( (v, k) => {
  //     const index = locations.findIndex( obj  => obj.name.toLowerCase().includes( v.toLowerCase() ) );
  //     if( index >= 0 ) {
  //       console.log('split: ', keywords.splice(k, 1));
  //       locationParam = locations[index].slug;
  //       params.set("location", locationParam);
  //       params.toString();
  //     }
  //   });
  // }

  /** Return true if exists some keyword into job texts */
  const findKeywords = (job) => {
    return keywords.every((sKeyword) => {
      job.requirements.requirements = job.requirements ? job.requirements.requirements : '';
      job.benefitsDescription.benefitsDescription = job.benefitsDescription ? job.benefitsDescription.benefitsDescription : '';
      let ourclient = job.ourclient ? job.ourclient.ourclient : '';
      const regExpKey = new RegExp('\\b' + sKeyword.toLowerCase() + '\\b', 'i');
      if (  regExpKey.test(job.title.toLowerCase()) ||
            regExpKey.test(job.requirements.requirements.toLowerCase()) ||
            regExpKey.test(job.benefitsDescription.benefitsDescription.toLowerCase()) ||
            regExpKey.test(ourclient.toLowerCase()) ||
            job.locations.some(obj => regExpKey.test( obj.name.toLowerCase() )) ||
            job.skills.some(obj => regExpKey.test( obj.name.toLowerCase() ))
      ) {
        return true;
      }
      return false;
    }
    )
  }

  if (jobs) {
    jobs = jobs.filter(function (el) {
      return findKeywords(el)
        && (locationParam === 'all' ? true : el.locations.some(obj => obj.slug === locationParam))
        && (skillParam === 'all' ? true : el.skills.some(obj => obj.slug === skillParam))
      // &&  ( (remoteParam && el.remote) || (officeParam && el.office) )
    });
  }
  const jobsList = jobs.map(({ jobSlug, title, locations, description, remote, office, urgent }) => (
    {
      title: title,
      location: locations.map((item) => (item["shortName"])).join(" / "),
      jobLocation: locations.map((item) => (item["name"])).join(" / "),
      url: "/careers/jobs/" + jobSlug + "/",
      urgent: urgent,
      tags: { "remote": remote, "office": office }
    }
  ));

  // const search = keywordParam + " Location: " + locationParam + " Skill: " + skillParam;
  let search = keywordParam !== '' ? keywordParam : '';
  search += (keywordParam !== '' && (locationParam !== 'all' || skillParam !== 'all') ? ' - ' : '');
  search += (locationParam !== 'all' ? locations.find(element => element.slug === locationParam).name + ' ' : '');
  search += (locationParam !== 'all' && skillParam !== 'all' ? ' & ' : '');
  search += (skillParam !== 'all' ? skills.find(element => element.slug === skillParam).name + ' ' : '');

  return (
    <>
      <Layout breadcrumbMap={breadcrumbMap}>
        <div className="max-container-pages">
          <Seo title={pageTitle} description={pageDescription} lang={lang} meta={meta} />
          <JobSearchForm params={params} skills={skills} locations={locations}></JobSearchForm>
          <JobOpenings jobs={jobsList} search={search} mainTitle={false} locations={locations}></JobOpenings>
          <FindDreamJob></FindDreamJob>
        </div>
      </Layout>
    </>
  )
}

export default JobOpportunitiesPage
